import { useState, useEffect } from 'react';
import Highlight from '@App/model/similarSearch/Highlight';
import Waveform from '@App/components/model/Waveform';
import Loader from '@App/components/ui/Loader';

interface Props {
  waveformId: string | undefined
  reference: string
  audioFileUrl: string | null
  waveformUrl: string | null
  progress: number | null
  duration: number
  light: boolean
  highlights: Highlight[]
  cursor: boolean
  onSegmentChange: () => void
  setIsPlaying: () => void
}

export default function WaveformByUrl({
  waveformId,
  reference,
  audioFileUrl,
  waveformUrl,
  progress,
  duration,
  light,
  highlights,
  cursor,
  onSegmentChange,
  setIsPlaying,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [waveformData, setWaveformData] = useState<number[]>([]);

  /**
   * Fetches the waveform data
   */
  useEffect(() => {
    if (waveformUrl) {
      setLoading(true);
      fetch(waveformUrl)
        .then(res => res.json())
        .then(res => {
          setWaveformData(res);
        }).finally (() => {
          setLoading(false);
        });
    }
  }, [waveformUrl]);

  return <>
    {waveformData && <Waveform
      data={waveformData}
      id={waveformId ?? reference}
      audioFileUrl={audioFileUrl}
      duration={duration}
      highlights={highlights}
      progress={progress}
      light={light}
      cursor={cursor}
      onSegmentChange={onSegmentChange}
      setIsPlaying={setIsPlaying}
    />}
    {loading && <Loader/>}
  </>;
}
