import { Component } from 'react';
import I18n from 'i18n-js';
import { HUBSPOT_PAGES } from '@App/routes';
import HubspotLink from '@App/components/navigation/HubspotLink';

class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="container-fluid">
                    <div className="footer__legals">
                        <p>
                            © Musique & Music {new Date().getFullYear()}
                            {' - '}
                            <HubspotLink route={HUBSPOT_PAGES.CGU}>{I18n.t('footer.cgu')}</HubspotLink>
                            {' - '}
                            <HubspotLink route={HUBSPOT_PAGES.CGL}>{I18n.t('footer.cgl')}</HubspotLink>
                            {' - '}
                            <a href="https://www.musique-music.com/mentions-legale">{I18n.t('footer.legal_notices')}</a>
                        </p>
                    </div>
                    <ul className="footer__social">
                        <li>
                            <a href="https://www.facebook.com/musiqueandmusicfrance/">
                                <i className="icon icon--facebook"/>
                                <span className="sr-only">Page Facebook de Musique & Music</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/musiquemusicfr">
                                <i className="icon icon--twitter"/>
                                <span className="sr-only">Page Twitter de Musique & Music</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/musiquemusic/">
                                <i className="icon icon--linkedin"/>
                                <span className="sr-only">Page Linkedin de Musique & Music</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/c/Musique-music">
                                <i className="icon icon--youtube"/>
                                <span className="sr-only">Page Youtube de Musique & Music</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/_musique_music/">
                                <i className="icon icon--instagram"/>
                                <span className="sr-only">Page Instagram de Musique & Music</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
        );
    }
}

export default Footer;
