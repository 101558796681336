import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setPlaylist } from '@App/store/actions/player';
import TrackVersion from '@App/components/model/TrackVersion';

class TrackVersionList extends Component {
    componentDidMount() {
        this.loadPlaylist();
    }

    componentWillUnmount() {
        this.props.resetPlaylist();
    }

    componentDidUpdate(prevProps) {
        const { versions } = this.props;

        // Versions have changed
        if (JSON.stringify(versions) !== JSON.stringify(prevProps.versions)) {
            this.loadPlaylist();
        }
    }

    loadPlaylist() {
        const { loadPlaylist, versions } = this.props;
        const playlist = versions.map(version => ({
            trackReference: version.track.reference,
            versionReference: version.reference,
            highlights: version.highlights ?? []
        }));

        loadPlaylist(playlist);
    }

    render() {
        const { versions, projectId } = this.props;

        return (
            <div key="track-list" id="track-list">
                {versions.map((version, position) => <TrackVersion
                    key={version.reference}
                    version={version}
                    projectId={projectId}
                    searchResultsPosition={position + 1}
                />)}
            </div>
        );
    }
}

const Wrapper = connect(
    null,
    dispatch => ({
        loadPlaylist: playlist => dispatch(setPlaylist(playlist)),
        resetPlaylist: () => dispatch(setPlaylist()),
    }),
)(TrackVersionList);

Wrapper.propTypes = {
    versions: PropTypes.arrayOf(
        PropTypes.shape(TrackVersion.propTypes).isRequired
    ).isRequired,
    projectId: PropTypes.string,
};

Wrapper.defaultProps = {
    projectId: null,
};

TrackVersionList.propTypes = {
    ...Wrapper.propTypes,
    loadPlaylist: PropTypes.func.isRequired,
    resetPlaylist: PropTypes.func.isRequired,
};

TrackVersionList.defaultProps = {
    ...Wrapper.defaultProps,
};

export default Wrapper;

export function mapTrackToVersion(track, highlights = []) {
    const { mainVersion } = track;
    const { reference, waveformUrl, duration, audioFileUrl, previewAudioFileUrl, availableForSimilarSearch, label, adminEditUrl } = mainVersion;

    return {
        reference,
        waveformUrl,
        duration,
        audioFileUrl,
        previewAudioFileUrl,
        availableForSimilarSearch,
        label,
        adminEditUrl,
        main: true,
        track: {
            reference: track.reference,
            title: track.title,
            nbVersions: track.nbVersions,
            album: track.album,
            createdAt: track.createdAt
        },
        highlights
    };
}
