import Highlight from '@App/model/similarSearch/Highlight';
import { formatDuration } from '@App/utils';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Seeker from '@App/components/ui/Seeker';
import { useAudioApi } from '@App/hooks/useAudioApi';

/**
 * This Waveform component receives waveform data (audio spectrum as array of integers) that can be quite voluminous.
 * In lists, rather use WaveformByUrl component, a wrapper that fetches these waveform data asynchronously before rendering
 * this Waveform component.
 *
 * @see WaveformByUrl
 */

Waveform.HEIGHT = 24;
Waveform.BITS = 8;
Waveform.AMPLITUDE = Math.pow(2, Waveform.BITS) / 2;
Waveform.SPACING = 3;

Waveform.propTypes = {
    id: PropTypes.string.isRequired,
    audioFileUrl: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    progress: PropTypes.number,
    duration: PropTypes.number.isRequired,
    light: PropTypes.bool,
    highlights: PropTypes.arrayOf(Highlight),
    cursor: PropTypes.bool,
    onSegmentChange: PropTypes.func,
    setIsPlaying: PropTypes.func,
};

Waveform.defaultProps = {
    progress: 0,
    light: false,
    data: [],
    highlights: [],
    cursor: false,
};

export default function Waveform({
    id,
    audioFileUrl,
    data,
    progress,
    duration,
    light,
    highlights,
    cursor,
    onSegmentChange,
    setIsPlaying,
}) {
    const { HEIGHT } = Waveform;
    const height = useMemo(() => getHeight(), []);
    const spacing = cursor ? 5 : Waveform.SPACING;
    const width = useMemo(() => getWidth(data, spacing), [data, spacing]);
    const path = useMemo(() => getPath(data, true, spacing), [data, spacing]);
    const handleStart = () => {
        if(duration < 60) {
            return 0;
        }

        if (duration > 60 && duration < 90) {
            return duration - 63;
        }

        return 30;
    };

    const handleEnd = () => {
        if(duration > 60 && duration < 90) {
            return duration - 3;
        }

        if(duration > 90) {
            return 90;
        }

        return duration - 3;
    };

    const [selectionStart, setSelectionStart] = useState(handleStart);
    const [selectionEnd, setSelectionEnd] = useState(handleEnd);
    const startCursorX = (width * selectionStart) / duration;
    const endCursorX = (width * selectionEnd) / duration;
    const startRef = useRef(null);
    const endRef = useRef(null);

    useEffect(() => {
        if(selectionEnd > duration && duration > 60) {
            setSelectionEnd(duration - 3);
        }

        if(selectionStart > duration && duration > 60) {
            setSelectionStart(duration - 63);
        }
    }, [duration, selectionEnd, selectionStart]);

    const handleMoveStart = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const initialX = e.clientX;
        const initialStartX = startCursorX;

        const handleMouseMove = (e) => {
            e.preventDefault();
            e.stopPropagation();

            const offsetX = e.clientX - initialX;
            const newStartX = initialStartX + offsetX;
            const newStartSeconds = (newStartX / width) * duration;

            if (
                newStartSeconds >= 0 &&
                newStartSeconds <= selectionEnd - Segment.MIN_DURATION &&
                selectionEnd - newStartSeconds <= Segment.MAX_DURATION
            ) {
                setSelectionStart(newStartSeconds);
                startRef.current.value = formatDuration(newStartSeconds);
                onSegmentChange(newStartSeconds, selectionEnd);
            }
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleMoveEnd = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const initialX = e.clientX;
        const initialEndX = endCursorX;

        const handleMouseMove = (e) => {
            e.preventDefault();
            e.stopPropagation();

            const offsetX = e.clientX - initialX;
            let newEndX = initialEndX + offsetX;
            newEndX = Math.min(width - 20, newEndX);

            const newEndSeconds = (newEndX / width) * duration;

            if (
                newEndSeconds >= selectionStart + Segment.MIN_DURATION &&
                newEndSeconds <= selectionStart + Segment.MAX_DURATION
            ) {
                setSelectionEnd(newEndSeconds);
                endRef.current.value = formatDuration(newEndSeconds);
                onSegmentChange(selectionStart, newEndSeconds);
            }
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleInput = (e, setSelection, selectionValue, otherSelectionValue, ref, isStart) => {
        const value = e.target.value;

        const isValidTimeFormat = /^([0-5]?[0-9]):([0-5][0-9])$/.test(value);

        if (!isValidTimeFormat) {
            setSelection(selectionValue);
            ref.current.value = formatDuration(selectionValue);

            return;
        }

        const [minutes, seconds] = value.split(':');
        let totalSeconds = parseInt(minutes, 10) * 60 + parseInt(seconds, 10);

        if (
            ((isStart && totalSeconds > otherSelectionValue) ||
                (!isStart && totalSeconds < otherSelectionValue)) ||
            (totalSeconds > duration)
        ) {
            setSelection(selectionValue);
            ref.current.value = formatDuration(selectionValue);

            return;
        }

        if (totalSeconds <= duration) {
            if (Math.abs(otherSelectionValue - totalSeconds) <= 60) {
                setSelection(isNaN(totalSeconds) ? 0 : totalSeconds);

                return;
            }

            setSelection(otherSelectionValue + (totalSeconds > otherSelectionValue ? 60 : -60));

            return;
        }

        setSelection(duration);
    };

    const handleInputStart = (e) => {
        handleInput(e, setSelectionStart, selectionStart, selectionEnd, startRef, true);
    };

    const handleInputEnd = (e) => {
        handleInput(e, setSelectionEnd, selectionEnd, selectionStart, endRef, false);
    };

    const audio = useAudioApi();
    function onSeek(position) {
        audio.load(audioFileUrl);
        audio.seek(position * duration);

        if (setIsPlaying) {
            setIsPlaying(true);
        }
    }

    return <div className="position-relative">
        {cursor && (
            <div style={{top: '-21.5px', position: 'absolute'}}>
                <div style={{position: 'absolute', top: '-21.5px', display: 'flex'}}>
                    <input ref={startRef} type="text" style={{width: '30px', height: '20px', fontSize: 9, padding: 1, left: `${startCursorX - 10}px`, position: 'absolute', transform: 'translateX(-50%)'}} defaultValue={formatDuration(selectionStart)} onBlur={handleInputStart} />
                    <input ref={endRef} type="text" style={{width: '30px', height: '20px', fontSize: 9, padding: 1, left: `${endCursorX + 10}px`, position: 'absolute', transform: 'translateX(40%)'}} defaultValue={formatDuration(selectionEnd)} onBlur={handleInputEnd} />
                </div>
                <svg id="#text" className="text" width={width} height={15} viewBox={`-10 -${15} ${width} ${15}`}>
                    <rect
                        x={startCursorX - 11}
                        y={-14}
                        width={11}
                        height="110%"
                        fill="#2378c1"
                        cursor="move"
                        onMouseDown={handleMoveStart}
                    />
                    <rect
                        x={endCursorX}
                        y={-14}
                        width={11}
                        height="110%"
                        fill="#2378c1"
                        cursor="move"
                        onMouseDown={handleMoveEnd}
                    />
                </svg>
            </div>
        )}
        <Seeker onSeek={onSeek} startCursorX={startCursorX} endCursorX={endCursorX} duration={duration}>
            <svg
                className={`waveform ${light ? 'waveform--light' : ''}`}
                width={width}
                height={height}
                viewBox={`-10 -${HEIGHT} ${width} ${height}`}
                xmlns="http://www.w3.org/2000/svg"
            >
                <ProgressMasks id={id} progress={progress} />
                <path className={`waveform__path ${cursor && 'waveform__path--bold'}`} d={path} mask={progress ? `url(#remaining-${id})` : undefined} />
                {progress && <path className={`waveform__path waveform__path--played ${cursor && 'waveform__path--bold'}`} d={path} mask={`url(#played-${id})`} />}
                <Highlights highlights={highlights} duration={duration} />
                {cursor && <Segment width={width}
                    height={height}
                    duration={duration}
                    startCursorX={startCursorX}
                    endCursorX={endCursorX}
                    selectionStart={selectionStart}
                    setSelectionStart={setSelectionStart}
                    selectionEnd={selectionEnd}
                    setSelectionEnd={setSelectionEnd}
                    onSegmentChange={onSegmentChange}
                    handleMoveEnd={handleMoveEnd}
                    handleMoveStart={handleMoveStart}
                    startRef={startRef}
                    endRef={endRef}
                />}
            </svg>
        </Seeker>
    </div>;
}

ProgressMasks.propTypes = {
    id: PropTypes.string.isRequired,
    progress: PropTypes.number,
};

function ProgressMasks({ id, progress = 0 }) {
    const percent = useMemo(() => (progress * 100).toFixed(3), [progress]);

    if (!progress) {
        return null;
    }

    return <>
        <mask key="played" id={`played-${id}`}>
            <rect x="0" y="-50%" width={`${percent}%`} height="100%" fill="white" />
            <rect x={`${percent}%`} y="-50%" width="100%" height="100%" fill="black" fillOpacity={0.2}/>
        </mask>
        <mask key="remaining" id={`remaining-${id}`}>
            <rect x="0" y="-50%" width={`${percent}%`} height="100%" fill="black" />
            <rect x={`${percent}%`} y="-50%" width="100%" height="100%" fill="white" />
        </mask>
    </>;
}

Highlights.propTypes = {
    highlights: PropTypes.arrayOf(Highlight),
    duration: PropTypes.number,
};

function Highlights({ highlights = [], duration }) {
    if (highlights?.length === 0) {
        return null;
    }

    return highlights.map((highlight, index) => {
        const start = highlight.offset / duration;
        const end = (highlight.offset + highlight.duration) / duration;
        const width = end - start;

        return <rect
            key={`highlight-${index}`}
            y="-50%"
            x={`${(start * 100).toFixed(3)}%`}
            width={`${(width * 100).toFixed(3)}%`}
            height="100%"
            fill="#64A3F8"
            fillOpacity="0.2"
        />;
    });
}

function getPath(data, useRelativeAmplitude = true, spacing) {
    const { length } = data;
    const amplitude = useRelativeAmplitude ? getMax(data) : Waveform.AMPLITUDE;
    let path = '';

    for (let i = 0; i < length; i += 2) {
        path += getLine(i / 2, ...data.slice(i, i + 2), amplitude, spacing);
    }

    return path;
}

function getWidth(data, spacing) {
    return data.length / 2 * spacing;
}

function getHeight() {
    return Waveform.HEIGHT * 2;
}

function getMax(data) {
    return Math.max(
        Math.max(...data),
        Math.abs(Math.min(...data))
    );
}

function getLine(index, left, right, amplitude, spacing) {
    const { HEIGHT } = Waveform;
    const x = index * spacing + spacing / 2;
    const y = right / amplitude * HEIGHT;
    const height = left / amplitude * HEIGHT;

    return `M ${x} ${y} V ${height} `;
}

Segment.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    startCursorX: PropTypes.number.isRequired,
    endCursorX: PropTypes.number.isRequired,
    selectionStart: PropTypes.number.isRequired,
    setSelectionStart: PropTypes.func.isRequired,
    selectionEnd: PropTypes.number.isRequired,
    setSelectionEnd: PropTypes.func.isRequired,
    onSegmentChange: PropTypes.func.isRequired
};


Segment.MIN_DURATION = 5;
Segment.MAX_DURATION = 60;

function Segment({
    width,
    height,
    duration,
    startCursorX,
    endCursorX,
    selectionStart,
    setSelectionStart,
    selectionEnd,
    setSelectionEnd,
    onSegmentChange,
    handleMoveStart,
    handleMoveEnd,
    startRef,
    endRef,
}) {
    let highlightX = startCursorX;
    let highlightWidth = Math.min(Math.max(0, endCursorX - startCursorX), width);

    const [isDragging, setIsDragging] = useState(false);
    const initialXRef = useRef(null);
    const initialStartXRef = useRef(null);
    const initialEndXRef = useRef(null);

    const handleMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
        initialXRef.current = e.clientX;
        initialStartXRef.current = startCursorX;
        initialEndXRef.current = endCursorX;
    };

    const handleMouseMove = useCallback((e) => {
        if (isDragging) {
            e.preventDefault();
            e.stopPropagation();
            const offsetX = e.clientX - initialXRef.current;
            const newStartX = initialStartXRef.current + offsetX;
            const newEndX = initialEndXRef.current + offsetX;
            const newStartSeconds = (newStartX / width) * duration;
            const newEndSeconds = (newEndX / width) * duration;

            if (
                newStartSeconds >= 0 &&
                newStartSeconds <= selectionEnd - Segment.MIN_DURATION &&
                newEndSeconds <= duration &&
                newEndSeconds >= selectionStart + Segment.MIN_DURATION
            ) {
                setSelectionStart(newStartSeconds);
                startRef.current.value = formatDuration(newStartSeconds);
                setSelectionEnd(newEndSeconds);
                endRef.current.value = formatDuration(newEndSeconds);
                onSegmentChange(newStartSeconds, newEndSeconds);
            }
        }
    }, [isDragging, width, duration, selectionEnd, selectionStart, setSelectionStart, startRef, setSelectionEnd, endRef, onSegmentChange]);


    const handleMouseUp = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        initialXRef.current = null;
        initialStartXRef.current = null;
        initialEndXRef.current = null;
    }, []);

    return (
        <>
            <line
                x1={startCursorX}
                y1={-height}
                x2={startCursorX}
                y2={height}
                stroke="#2378c1"
                strokeWidth="3"
                cursor="col-resize"
                onMouseDown={handleMoveStart}
            />
            <line
                x1={endCursorX}
                y1={-height}
                x2={endCursorX}
                y2={height}
                stroke="#2378c1"
                strokeWidth="3"
                cursor="col-resize"
                onMouseDown={handleMoveEnd}
            />
            <rect
                x={highlightX}
                y={-height}
                width={highlightWidth}
                height="200%" fill="#64A3F8" fillOpacity="0.2"
                cursor={isDragging ? 'grabbing' : 'grab'}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            />
        </>
    );
}
